import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

export function useUrlAnchor() {
  const router = useRouter();

  const addAnchorToUrl = (anchor: string) => router.push({ hash: "#" + anchor });
  const clearAnchorFromUrl = () => router.push({ hash: "" });

  const route = useRoute();
  const currentAnchor = computed(() => route.hash.substring(1));

  return {
    addAnchorToUrl,
    clearAnchorFromUrl,
    currentAnchor,
  };
}
