import { translate } from "@/services/translation";
import { ContentWidth, Layout } from "@/types";
import type { RouteLocation } from "vue-router";

export enum AppRouteName {
  addItem = "addItem",
  myItemDetail = "myCollectionItemDetail",
  collectionCategory = "collectionCategory",
  myCollection = "collectionSummary",
  catalogue = "catalogueStart",
  catalogueItem = "catalogueItem",
  catalogueCategory = "catalogueCategory",
  dashboard = "dashboard",
  notifications = "notifications",
  userSettings = "profileSettings",
  myProfile = "myProfile",
  profileInitialization = "profileInitialization",
  articleDraft = "articleDraft",
  ebayListing = "ebay",
  chat = "chat",
  chatConversation = "chatConversation",
  chatEmailNotificationsUnsubscribed = "chatUnsubscribed",
  chatEmailNotificationsUnsubscribeError = "chatUnsubscribeError",
}

export const appRoutes = [
  /**
   * Magazine
   */

  {
    name: AppRouteName.articleDraft,
    path: "/magazin/nahled/:id(\\d+)-:url/",
    components: { mainContent: () => import("@/views/ArticleDetail.vue") },
    meta: {
      title: "Magazín",
      layout: { name: Layout.app, contentWidth: ContentWidth.full },
      breadcrumbs: [{ text: "Magazín" }],
    },
  },

  /**
   * My collection
   */

  {
    path: "/moje-sbirka",
    children: [
      {
        path: "",
        name: AppRouteName.myCollection,
        components: { mainContent: () => import("@/views/app/myCollection/Overview.vue") },
        meta: {
          title: "Moje sbírka",
          layout: { name: Layout.app },
          breadcrumbs: [{ text: "Moje sbírka" }],
        },
      },
      {
        name: AppRouteName.collectionCategory,
        path: ":url",
        components: { mainContent: () => import("@/views/app/myCollection/Category.vue") },
        meta: {
          title: "Moje sbírka",
          layout: { name: Layout.app },
          breadcrumbs: [{ to: { name: AppRouteName.myCollection }, text: "Moje sbírka" }],
        },
      },
    ],
  },

  /**
   * My item detail
   */

  {
    name: AppRouteName.myItemDetail,
    path: "/muj-predmet/:id(\\d+)/:nameSeoSlug?",
    components: { mainContent: () => import("@/views/app/myCollection/ItemDetail.vue") },
    meta: {
      title: "Moje sbírka",
      layout: { name: Layout.app },
    },
  },

  /**
   * Add item
   */

  {
    name: AppRouteName.addItem,
    path: "/pridat-predmet",
    components: { mainContent: () => import("@/views/app/AddItem.vue") },
    meta: {
      title: "Přidat předmět",
      layout: {
        name: Layout.appDialog,
        width: "small",
        height: "dynamic",
        class: "add-item-dialog",
      },
    },
  },

  /**
   * Dashboard
   */

  {
    name: AppRouteName.dashboard,
    path: "/nastenka",
    components: { mainContent: () => import("@/views/app/AppDashboard.vue") },
    meta: {
      title: "Nástěnka",
      layout: {
        name: Layout.app,
        contentWidth: ContentWidth.full,
      },
      breadcrumbs: [
        {
          text: "Nástěnka",
        },
      ],
    },
  },

  /**
   * Database
   */

  {
    path: "/databaze",
    children: [
      {
        path: "",
        name: AppRouteName.catalogue,
        components: { mainContent: () => import("@/views/app/catalogue/Intro.vue") },
        meta: {
          title: "Databáze",
          breadcrumbs: [{ text: "Databáze" }],
          layout: { name: Layout.app },
        },
      },
      {
        name: AppRouteName.catalogueCategory,
        path: ":categoryUrl",
        components: { mainContent: () => import("@/views/app/catalogue/Category.vue") },
        meta: {
          title: "Databáze",
          breadcrumbs: [{ text: "Databáze", to: { name: AppRouteName.catalogue } }],
          layout: { name: Layout.app },
        },
      },
      {
        name: AppRouteName.catalogueItem,
        path: ":categoryUrl/:itemId(\\d+)/:nameSeoSlug?",
        components: { mainContent: () => import("@/views/app/catalogue/ItemDetail.vue") },
        meta: {
          title: "Detail předmětu",
          breadcrumbs: [{ text: "Databáze", to: { name: AppRouteName.catalogue } }],
          layout: { name: Layout.app },
        },
      },
    ],
  },

  /**
   * User profile
   */

  {
    name: AppRouteName.userSettings,
    path: "/nastaveni-profilu",
    components: { mainContent: () => import("@/views/app/UserSettings.vue") },
    meta: {
      title: "Nastavení profilu",
      layout: {
        name: Layout.app,
        contentWidth: ContentWidth.narrow,
      },
      breadcrumbs: [
        {
          text: "Nastavení profilu",
        },
      ],
    },
  },
  {
    name: AppRouteName.myProfile,
    path: "/muj-profil",
    components: { mainContent: () => import("@/views/app/ProfileMy.vue") },
    meta: {
      title: translate("views.profileMy.heading"),
      layout: {
        name: Layout.app,
        contentWidth: ContentWidth.middle,
      },
      breadcrumbs: [
        {
          text: translate("views.profileMy.heading"),
        },
      ],
      preventScrollToTop: true,
    },
  },

  /**
   * Ebay
   */

  {
    name: AppRouteName.ebayListing,
    path: "/nacenovac",
    components: { mainContent: () => import("@/views/app/EbayListing.vue") },
    meta: {
      title: "Naceňovač",
      layout: {
        name: Layout.app,
        contentWidth: ContentWidth.middle,
      },
      breadcrumbs: [
        {
          text: "Naceňovač",
        },
      ],
    },
  },

  /**
   * Chat
   */

  {
    path: "/chat/:catchAll(.*)?", // Match any path that starts with /chat
    redirect: (to: RouteLocation) => {
      const remainingPath = to.params.catchAll;
      return `/zpravy/${remainingPath}`;
    },
  },
  {
    path: "/zpravy/byl-jsi-uspesne-odhlasen",
    name: AppRouteName.chatEmailNotificationsUnsubscribed,
    components: { mainContent: () => import("@/views/ChatEmailNotificationsUnsubscribed.vue") },
    meta: {
      public: true,
      title: translate("views.chat.unsubscribe.title"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [
        {
          to: { name: AppRouteName.chat },
          text: translate("views.chat.breadcrumbs"),
        },
        {
          text: translate("views.chat.unsubscribe.breadcrumb"),
        },
      ],
    },
  },
  {
    path: "/zpravy/odhlaseni-emailu-se-nepodarilo",
    name: AppRouteName.chatEmailNotificationsUnsubscribeError,
    components: { mainContent: () => import("@/views/ChatEmailNotificationsUnsubscribeError.vue") },
    meta: {
      public: true,
      title: translate("views.chat.unsubscribeError.title"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [
        {
          to: { name: AppRouteName.chat },
          text: translate("views.chat.breadcrumbs"),
        },
        {
          text: translate("views.chat.unsubscribeError.breadcrumb"),
        },
      ],
    },
  },
  {
    path: "/zpravy",
    meta: {
      title: translate("views.chat.pageTitle"),
      layout: {
        name: Layout.app,
        contentWidth: ContentWidth.wide,
      },
      breadcrumbs: [
        {
          text: translate("views.chat.breadcrumbs"),
        },
      ],
    },
    children: [
      {
        path: "",
        name: AppRouteName.chat,
        components: { mainContent: () => import("@/views/app/ChatView.vue") },
      },
      {
        path: ":buddyId(\\d+)/:buddyNickname",
        name: AppRouteName.chatConversation,
        components: { mainContent: () => import("@/views/app/ChatView.vue") },
      },
    ],
  },

  /**
   * Others
   */

  {
    name: AppRouteName.notifications,
    path: "/notifikace",
    components: { mainContent: () => import("@/views/app/NotificationsList.vue") },
    meta: {
      title: "Notifikace",
      layout: { name: Layout.app },
      breadcrumbs: [
        {
          text: "Notifikace",
        },
      ],
    },
  },
  {
    name: AppRouteName.profileInitialization,
    path: "/dokonceni-registrace",
    components: { mainContent: () => import("@/views/app/ProfileInitialization.vue") },
    meta: {
      title: translate("views.profileInitialization.title"),
      layout: {
        name: Layout.appDialog,
        height: "dynamic",
        width: "small",
        class: "profile-dialog",
      },
    },
  },
];
