export default {
  appLayout: {
    menu: {
      dashboard: "Nástěnka",
      myCollection: "Moje sbírka",
      publicItemsList: "Předměty sběratelů",
      magazine: "Magazín",
      catalogue: "Databáze",
    },
    itemsTable: {
      category: "Kategorie",
      name: "Název",
      pieces: "Kusů",
      note: "Poznámka",
      acquirePrice: "Pořizovací<br>cena / ks",
      currentPrice: "Orientační<br>cena / ks",
      itemVisibility: "Veřejný předmět",
    },
    topbar: {
      addItemBtn: "Přidat předmět",
      chat: {
        btnTitle: "@:views.chat.heading",
      },
      user: {
        btnTitle: "Uživatelské menu",
        settingsBtn: "Můj profil",
        logoutBtn: "Odhlásit se",
        logoutMsg: "Úspěšně ses odhlásil. Těším se příště.",
        pricesHidden: "Ceny jsme skryli.",
        pricesVisible: "Ceny se zobrazují.",
      },
      menu: {
        btnTitle: "Menu",
        ebay: "Naceňovač",
        termsAndConditions: "@:views.termsAndConditions.heading",
        contacts: "@:publicLayout.menu.contacts",
        about: "@:publicLayout.menu.about",
      },
      notifications: {
        btnTitle: "Oznámení",
        loading: "Načítám oznámení…",
        empty: "Aktuálně nemáš žádná oznámení.",
        showAll: "Zobrazit vše",
      },
    },
  },
};
