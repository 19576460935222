export default {
  chat: {
    heading: "Zprávy",
    breadcrumbs: "@:views.chat.heading",
    pageTitle: "@:views.chat.heading",
    deletedUserNickname: "Smazaný uživatel",
    inputPlaceholder: "Napište zprávu…",
    collectorsProfile: "Profil sběratele",
    error: {
      connectionNotCreated: "Nepodařilo se načíst zprávy.",
      streamError: "Nastala chyba při přenosu dat.",
      emptyMsg: "Nejdříve napiš svou zprávu.",
      messageTooLong: "Zpráva může být nejvýše { maxLength } znaků dlouhá.",
      buddyDoesntExists: "Uživateli nelze napsat zprávu, zřejmě nemá veřejný profil.",
      chatClosed: "Uživatel byl smazán, nelze mu tedy napsat zprávu.",
      messageNotSent: "Zprávu se nepodařilo odeslat.",
    },
    placeholderMsg: {
      noConversationCreated: "Zatím sis s nikým ještě nenapsal. Napiš sběratelům přes jejich veřejné předměty nebo přímo přes profil.",
      noConversationActive: "Začni tím, že si vybereš konverzaci&nbsp;vlevo.",
      noMessageInConversation: "Prozatím sis s uživatelem nevyměnil žádnou zprávu. Buď první, kdo napíše!",
    },
    card: {
      actions: {
        archive: "Archivovat chat",
      },
    },
    systemMsg: {
      userDeleted: "Sběratelův účet byl smazán.",
    },
    message: {
      myOriginFromItem: "Píši ohledně předmětu",
      myOriginIsDeletedItem: "Píši ohledně smazaného předmětu",
      buddyOriginFromItem: "Sběratel ti píše ohledně předmětu",
      buddyOriginIsDeletedItem: "Sběratel ti píše ohledně smazaného předmětu",
      byMe: "Od tebe",
      byBuddy: "Od",
      byDeletedBuddyPlaceholder: "smazaného uživatele",
    },
    archive: {
      title: "Archivace konverzace",
      text: "Opravdu chcete archivovat konverzaci?",
      yesBtn: "ano",
      noBtn: "ne",
      successMsg: "Konverzace byla archivována.",
    },
    unsubscribe: {
      title: "E-mailové notifikace zrušeny",
      breadcrumb: "Notifikace zrušeny",
      heading: "Byl jsi úšpěšně odhlášen",
      text: "V případě, že si to rozmyslíš a budeš chtít dostávat upozornění na své zprávy můžeš to udělat v",
      linkText: "nastavení svého profilu",
      buttonPublic: "Zpět domů",
      buttonUser: "Zpět na nástěnku",
    },
    unsubscribeError: {
      title: "E-mailové notifikace se nepodařilo zrušit",
      breadcrumb: "Notifikace nezrušeny",
      heading: "Odhlášení e-mailu se nepodařilo",
      text: "Tento <strong>link již není validní</strong>. Prosím, zkus najít jiný v&nbsp;novějším emailu. Nebo si toto nastavení můžeš změnit přímo v ",
      linkText: "nastavení svého profilu",
      buttonPublic: "Zpět domů",
      buttonUser: "Zpět na nástěnku",
    },
  },
};
